<template>
    <div class="Modelclothes public_bg">
        <Top  name="灵兽" email back v-if="topShow"></Top>
        <van-loading v-if="isLoading" color="#1989fa" />
        <div class="public_tw" v-else>
            <div class="public_sheet">
                <div class="public_main">
                    <div class="public_main_outside">
                        <div class="public_main_outside_top">灵兽</div>
                        <ul>
                            <li v-for="item in dressList" :key="item.id" @click="dressClick(item)">{{item.name}}：{{item.val}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <van-popup v-model="show" position="bottom" round>
            <div class="wrapper">
                <div class="block">
                    <div v-if="!loading">
                        <div class="wrapper_title orange">{{detailObj[0].val}}</div>
                        <ul class="wrapper_main">
                            <li v-for="(item , index) in detailObj" :key="index" v-if='index!=0'>
                                <div><span v-if="item.name"  class="blue">【{{item.name}}】：</span></div>
                                <div v-if="typeof item.val == 'string'">
                                    <span>{{item.val}}</span>
                                </div>
                                <div v-else-if="!item.name" v-for="(i , key) in item" :key="key">

                                    <span>{{i.name}}：</span>
                                    <span>{{i.val}}</span>
                                </div>
                                <div v-else v-for="(i , key) in item.val" :key="key">
                                    <span>{{i.name}}：</span>
                                    <span>{{i.val}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div style="padding-top: 10rem;text-align: center;" v-else>
                        <van-loading color="#0094ff" size="24px">加载中...</van-loading>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import Top from '@/components/top'
import "../../assets/css/common.css"

export default {
    name: "Spiritpet",
    data(){
        return {
            isLoading:true,
            show: false,
            dressList: [],
            params: {},
            detailObj: [],
            loading: true,
            topShow: true,
        }
    },
    components: {
      Top
    },
    created() {
        //获取路由参数
        this.params = this.$route.query;
        //有tag参数是app访问，需去掉头部
        if (this.params.tag) {
            this.topShow = false;
        }else{
            this.topShow = true;
        }
        //获取穿戴数据
        this.getEquipmentData();
    },
    methods: {
        //获取穿戴数据
        getEquipmentData() {
            this.$api.shopping.getPetList({
                goods_id: this.params.goods_id,
                // is_product: this.params.is_product
                // goods_id: 10000043,
                is_product: 1
            }).then(res => {
                this.isLoading = false
                if (res.code == 200) {
                    this.dressList = res.data.data[0].data
                }
                console.log(res)
            }).catch(err => {
                this.isLoading = false
                console.log(err);
            });
        },
        //灵兽详情弹窗
        dressClick(item) {
            // console.log(item)
            this.show = true;
            this.loading = true;
            //初始化
            this.detailObj={}
            // this.detailObj = {
            //     goods_name: '',
            //     hp: '',
            //     mp: "",
            //     need_lv: "",
            //     goods_lv: '',
            //     need_zslevel: "",
            //     goods_desp: "",
            //     additional_attribute: [],
            //     basics_attribute: [],
            //     element_attribute: [],
            //     suit_attribute: []
            // };
            // is_product	是	int	是否是从商品展示页请求 1是 0不是。用于区分上架, 上架前给卖家的实时展示game
            // goods_id	是	int	上架id
            // id	是	int	列表页获取的索引id
            this.$api.shopping.getDataFromIndex({
                goods_id: this.params.goods_id,
                is_product: 1,
                item_id: item.item_id
            }).then(res => {
                let detailObj = {}
                if (res.code == 200) {
                    //去掉占位图
                    this.loading = false;
                    detailObj = res.data
                    // for (var key in res.data) {
                    //     this.detailObj = res.data[key];
                    //     //处理额外属性
                    //     this.getArray(this.detailObj, 'additional_attribute');
                    //     //处理基础属性
                    //     this.getArray(this.detailObj, 'basics_attribute');
                    //     //处理元素属性
                    //     this.getArray(this.detailObj, 'element_attribute');
                    //     //处理套装属性
                    //     this.getArray(this.detailObj, 'suit_attribute');
                    // }
                    //console.log(this.detailObj);
                }
                delete detailObj.index
                //this.detailObj
                
                for(let i in detailObj){
                    if(detailObj[i].length!=0){
                        this.detailObj[Number(detailObj[i].id)-1] = detailObj[i]
                    }
                    
                    // console.log(detailObj[i])
                }
                // console.log(this.detailObj)
            }).catch(err => {
                console.log(err);
            });
        },
        arrSort(keyName) {
           return function (obj1, obj2) {
               var a = obj1[keyName], b = obj2[keyName];
               if (a < b) return -1
               else if (a > b) return 1
               else return 0
           }
        },
        //将对象转化成数组
        getArray(obj, key) {
            if (obj[key]) {
                obj[key] = Object.values(obj[key]);
            }else{
                obj[key] = [];
            }
        },
    }
    
}
</script>
<style scoped>
.wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    text-align: left;
}

.block {
    width: 100%;
    height: 21.375rem;
    background-color: #242424;
    /* border-radius: .5625rem; */
    /* padding: 0 .75rem; */
    border-top-left-radius: .5625rem;
    border-top-right-radius: .5625rem;
}
.orange{
    color: #ff4e00;
}
.blue{
    color: #0078ff;
}
.green{
    color: #229200;
}
.gray{
    color: #707070;
}
.black{
    color: #313131;
}
.wrapper .wrapper_title{
    font: .9375rem/2.4375rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    padding-top: .375rem;
    margin: 0 ;
    border-bottom: 1px dashed #3699ff;
    padding-left: 2rem;
}
.wrapper .wrapper_main{
    overflow-y: auto;
    max-height: 18rem;
    padding: 0 1rem;
    color: #fff;
}
.wrapper .wrapper_main li{
    font: .75rem/1.5625rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    height: 100%;
    border-bottom: 1px dashed #b3b3b3;
    padding: .2rem 1rem;
}
.star_w>span{
    vertical-align: middle;
}
.star_w>.van-rate{
    vertical-align: middle;
}
.Modelclothes .van-popup{
    background-color: #242424;
}
.Modelclothes .van-overlay{
    background-color: rgba(0,0,0,.5);
}
</style>